@keyframes dropdown {
  from {
    top: 0%;
  }
  to {
    top: 100%;
  }
}
@keyframes collapse-anim {
  from {
    top: 100%;
  }
  to {
    top: 0%;
  }
}
.dropdown-animation {
  animation: dropdown 200ms ease-out both;
}
.collapse-animation {
  animation: collapse-anim 200ms ease-out both;
  animation-delay: 200ms;
}
.dropdown-subContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: orange;
}
