@media (max-width: 930px) {
  .container {
    display: flex;
  }
}
@media (min-width: 931px) {
  .container {
    display: block;
  }
}
.container {
  width: 100%;
  justify-content: center;
  min-width: 330px;
  display: flex;
}

@media (max-width: 931px) {
  .tabsContainer {
    min-width: 330px;
  }
}
.tabsContainer {
  height: max-content;
  width: max-content;
  position: relative;
  margin-bottom: 25px;
  min-width: 330px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.claimTabs {
  position: relative;
  min-width: 330px;
  /* border-bottom: none !important; */
}
.svgTab {
  width: 25px;
  height: 25px;
  margin-left: 10px;
  filter: grayscale(100%) brightness(1.5);
}
.boxClaim {
  display: flex;
  height: max-content;
  position: relative;
  width: 100%;
  min-width: 330px;
  /* padding: 0px 20px; */
  border-radius: 0px 0px 6px 6px;
  border: 1px solid #e3e4eb;
  border-top: none;
  box-shadow: 0px 38.5185px 51.9185px rgba(0, 0, 0, 0.0425185),
    0px 8.14815px 13.2815px rgba(0, 0, 0, 0.0274815);
}

@media (max-width: 1120px) {
  .boxClaim {
    flex-direction: column;
    min-width: 330px;
    align-items: center;
    justify-content: center;
  }
}

#claimToken {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--almost-white);
  padding: 15px;
  min-width: 330px;
  width: 100%;
}

#claimToken .backdrop {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: black;
  opacity: 0.7;
  z-index: 2;
}

#claimToken .box {
  height: max-content;
  position: relative;
  width: 100%;
  min-width: 330px;
  padding: 36px;
  border-radius: 0px 0px 6px 6px;
  border: 1px solid #e3e4eb;
  border-top: none;
  box-shadow: 0px 38.5185px 51.9185px rgba(0, 0, 0, 0.0425185),
    0px 8.14815px 13.2815px rgba(0, 0, 0, 0.0274815);
}

#claimToken .inner-box {
  height: 100%;
  width: 475px;
  display: flex;
  flex-direction: column;
}
.claimTab {
  background-color: red;
}
#claimToken ul {
  min-width: 330px;
  display: flex;
  width: 100%;
}

#claimToken li {
  width: 25%;
  position: relative;
}

#claimToken li:nth-child(3) {
  width: 50%;
}

#claimToken li button {
  width: 100%;
}
@media (max-width: 931px) {
  #claimToken li {
    width: 30%;
  }
}

#claimToken input {
  padding: 15px 30px;
  height: 52px;
  font-size: 24px;
  width: 100%;
  border: none;
  color: #8e90a6;
  border-radius: 32px;
  background-color: #f5f5f5;
  display: flex;
  justify-content: flex-end;
}

#claimTtoken input:hover,
#claimToken input:focus {
  outline: none;
}

#claimToken input::active {
  border: blue;
}

input::placeholder {
  color: #8e90a6;
  font-size: 24px;
}

#claimToken .execute {
  border: none;
  width: 100%;
  height: 52px;
  padding: 16px;
  border-radius: 32px;
  font-weight: 700;
  font-size: 22px;
  color: white;
  background-color: var(--secondary);
  display: flex;
  justify-content: center;
  align-items: center;
}
#claimToken .nav-link {
  color: #1d1c39 !important;
  border-radius: 6px 6px 0px 0px;
}
#claimToken .nav-item .nav-link {
  color: #1d1c39 !important;
  border-radius: 6px 6px 0px 0px;
}

#claimToken .nav-link:focus,
.nav-link:hover {
  color: #1d1c39 !important;
}

#claimToken .disabled {
  background: rgba(97, 109, 109, 0.5);
  color: white;
  cursor: not-allowed;
}

#claimToken .execute:hover {
  filter: brightness(1.1);
}

#claimToken .input-holder {
  height: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

#claimToken .button-holder {
  height: 50%;
  width: 100%;
  display: flex;
  align-items: flex-end;
}

#claimToken .warning-img {
  height: auto;
  width: 30px;
  position: absolute;
  right: 10px;
}
.firstDiv {
  margin-top: 40px;
  min-width: 330px;
  width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.secondDiv {
  max-width: 450px;
  padding: 20px;
  width: 100%;
  min-width: 330px;
  justify-content: center;
  display: flex;
}

@media (max-width: 930px) {
  .firstDiv {
    width: 100%;
    min-width: none;
  }
}

@media (min-width: 931px) {
  .secondDiv {
    min-width: 350px;
  }
}
.secondBalance {
  margin-bottom: 15px;
  width: 100%;
  margin-top: 10px;
}
.thirdBalance {
  width: 100%;
  margin-bottom: 20px;
}
