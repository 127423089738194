#root {
  width: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;

  font-family: 'Geomanist', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.modal-how-to-buy {
  opacity: 0.85 !important;
  background-color: #000000 !important;
}

.tab .tab-pane:active:hover {
  border-bottom: none;
}

.modal-content-video-HTB {
  border: none;
  background-color: transparent;
}
/* .modal-video-HTB {
  backdrop-filter: blur(5px);
} */

:root {
  --primary: #30d860;
  --secondary: #6f4896;
  --headline: #616d6d;
  --almost-white: #fefefe;
  --white: #ffffff;
  --background-gray: #f6f7f7;
  --button-gray: #f2f2f5;
  --subgray: '#BBBCCA';
}

/* Override Modal backdrop */
.modal-backdrop {
  background-color: #fefefe;
}
