#claiming {
  width: 100%;
  min-width: 600px;
  /* position: relative; */
}

#claiming .row {
  padding: 0px;
  margin: 0px;
}

#claiming .claim-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  min-width: 200px;
}

#claiming .claim-column:nth-child(1) {
  border-radius: 0px 0px 0px 6px;
}

#claiming .claim-column:nth-child(odd) {
  background-color: #F8F8F8;
}

#claiming .claim-column:nth-child(even) {
  background-color: #EDEDED;
}

#claiming .claiming-label {
  font-size: 14px;
  color: #A7A6AC;
  margin-bottom: 10px;
  font-weight: 600;
  letter-spacing: 0.03em;
}

#claiming .claiming-value {
  font-size: 16px;
  color: #48475E;
  margin-bottom: 21px;
}

#claiming .contract-address-label {
  margin-top: 20px;
  font-size: 10px;
  margin-bottom: 11px;
}

#claiming .contract-address {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 22px;
  border-radius: 57px;
  color: #48475E;
  font-size: 12px;
  background-color: #F3F3F3;
  margin-bottom: 28px;
}

#claiming .claiming-hr {
  height: 2px;
  background-color: #D6D7DF;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 19px;
}

#claiming .claim-button {
  margin-top: 13px;
  margin-bottom: 51px;
  padding: 5px 15px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: white;
  background-color: #30D860;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 21px;
  min-width: 84px;
  height: 42px;
  border: none;
}

#claiming button.disabled {
  background-color: #aaaaaa;
  cursor: not-allowed;
}

#claiming .toast-holder {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  padding: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 1;
}


#claiming .toast {
  z-index: 1;
  opacity: 1;
  background-color: #fff !important;
  /* background-color: rgb(255 252 203) !important; */
}
#claiming .success {
  background-color: rgb(197, 255, 183) !important;
}
#claiming .rejected {
  background-color: rgb(255, 211, 211) !important;
}
#claiming .failed {
  background-color: rgb(255, 131, 131) !important;
}

#claiming .toast .toast-header {
  z-index: 1;
  /* background-color: rgb(255 252 203) !important; */
  opacity: 1 !important;
}

#claiming .toast .toast-body {
  opacity: 1 !important;
  font-weight: 500 !important;
  text-transform: capitalize;
}

#claiming .spinner-border-sm {
  border-width: 2px;
}

/* #claiming .toast {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 120px;
  width: 200px;
  border-radius: 8px;
  background-color: green;
} */

@media (max-width: 1120px) {
  #claiming .claim-column:nth-child(1) {
    border-radius: 0px;
  }

  #claiming {
    min-width: 300px;
  }

  #claiming .claim-column {
    min-width: 240px;
  }
}

@media (max-width: 500px) {
  #claiming .claim-column {
    min-width: 100px;
  }
}